export function getBookingsListTableHeaders(value: string) {
  return [
    'Booking #',
    'Category',
    `${value}`,
    'Status',
    'Pickup location',
    'Destination',
    'Passenger',
    'Distance',
    'Price',
    'Chauffeur',
  ]
}

export const MESSAGES = {
  EN: {
    'tableHeaders.Booking #': 'Booking #',
    'tableHeaders.Category': 'Category',
    'tableHeaders.Booked At': 'Booked At',
    'tableHeaders.Starts At': 'Starts At',
    'tableHeaders.Status': 'Ride Status',
    'tableHeaders.Pickup location': 'Pickup location',
    'tableHeaders.Destination': 'Destination',
    'tableHeaders.Passenger': 'Passenger',
    'tableHeaders.Distance': 'Distance',
    'tableHeaders.Price': 'Price',
    'tableHeaders.Chauffeur': 'Chauffeur',
  },
}
