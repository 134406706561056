import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Void: any;
};

export type AcceptOfferError = {
  __typename?: 'AcceptOfferError';
  message: Scalars['String'];
  description: Scalars['String'];
};

export type AcceptOfferResponse = {
  __typename?: 'AcceptOfferResponse';
  /** ID of the acceptance request. */
  id?: Maybe<Scalars['ID']>;
  /** Validation errors (if any). */
  errors?: Maybe<Array<Maybe<AcceptOfferError>>>;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Int'];
};

/** AdminUser */
export type AdminUser = {
  __typename?: 'AdminUser';
  uuid: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
};

/** Return suggestions for string */
export type AutoSuggestions = {
  __typename?: 'AutoSuggestions';
  items?: Maybe<Array<Scalars['String']>>;
};

/** Booker details */
export type Booker = {
  __typename?: 'Booker';
  /** Booker UUID. */
  uuid?: Maybe<Scalars['String']>;
  /** Booker first name. */
  firstName: Scalars['String'];
  /** Booker last name. */
  lastName: Scalars['String'];
  /** Booker email address */
  email: Scalars['String'];
  /** Booker phone number */
  phoneNumber: Scalars['String'];
};

export type Booking = {
  __typename?: 'Booking';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Types of services to be booked. */
  services: Array<Service>;
  /** Booking status. */
  status: BookingStatus;
  /** Details about the payment method used */
  paymentDetails: PaymentDetails;
  /** Invoice information */
  invoice?: Maybe<Invoice>;
  /** Details of the Booker (first, last name, email and phone) */
  booker: Booker;
  /**
   * Contains price details, total price, tax, net price, currency
   * Values are in intergers in cents, e.g. 25000 would mean 250,00 EUR / USD
   */
  price: Price;
  /** Corporation details */
  corporation?: Maybe<Corporation>;
  /** Booking number */
  number: Scalars['String'];
  /** Cause for rejection/denial */
  cause?: Maybe<Scalars['String']>;
  /** SubCause for rejection/denial. Provides more fine-grained details for the cause */
  subCause?: Maybe<Scalars['String']>;
};

/** The possible filters options for user's bookings. */
export type BookingFilters = {
  timeState: TimeState;
  status?: Maybe<Array<BookingStatus>>;
  dateRange?: Maybe<DateRange>;
  matching?: Maybe<Scalars['String']>;
  serviceScope?: Maybe<ServiceScope>;
  serviceType?: Maybe<ServiceType>;
  bookingNumber?: Maybe<Scalars['String']>;
  /**
   * TODO: Find a way to move category as part of the ride,
   * as it is not related to the booking but related the service type.
   */
  category?: Maybe<RideCategory>;
};

/** Return type of the booking query */
export type BookingPayload = {
  __typename?: 'BookingPayload';
  item?: Maybe<Booking>;
};

/** Response for creating a booking. */
export type BookingResponse = {
  __typename?: 'BookingResponse';
  /** Unique ID of the booking request. */
  id: Scalars['ID'];
};

/** Return type of the bookings query */
export type BookingsPayload = {
  __typename?: 'BookingsPayload';
  items: Array<Booking>;
  pageInfo: PageInfo;
};

/** The possible statuses for a booking. */
export enum BookingStatus {
  /** All the bookings statuses below. */
  All = 'all',
  /**
   * The booking was confirmed by Blacklane as going ahead.
   * new, accepted in elli
   */
  Confirmed = 'confirmed',
  /**
   * The booking was rejected by Blacklane.
   * rejected in elli
   */
  Rejected = 'rejected',
  /**
   * The booking is currently being fulfilled
   * driver_on_the_way, driver_at_pickup, passenger_on_board, before, pickup, during in elli
   */
  Ongoing = 'ongoing',
  /**
   * The booking was canceled by the booker.
   * canceled_by_passenger, canceled_by_admin in elli
   */
  Canceled = 'canceled',
  /**
   * The booking was completed by Blacklane (all services provided) or was a no show.
   * finished, no_show in elli
   */
  Completed = 'completed',
  /** The booking was denied for some reason (probably because of invalid price). */
  Denied = 'denied',
  /** The booking has been created and awaits for validations. */
  New = 'new'
}

export type BookingStatusUpdated = {
  __typename?: 'BookingStatusUpdated';
  id: Scalars['ID'];
  status: BookingStatus;
  cause?: Maybe<Scalars['String']>;
  subCause?: Maybe<Scalars['String']>;
};

export type BookingUpdated = {
  __typename?: 'BookingUpdated';
  /** Current booking uuid (this may change after updates to certain fields) */
  id: Scalars['ID'];
  /** UUID to match with the original update transaction */
  transactionId: Scalars['ID'];
  /** Information if the update was successful */
  success: Scalars['Boolean'];
  /** Updated booking */
  booking?: Maybe<BookingPayload>;
  /** Information about error if the update was unsuccessful */
  error?: Maybe<UpdateError>;
};

export type BookingUpdateResponse = {
  __typename?: 'BookingUpdateResponse';
  /** The uuid of BookingUpdate transaction */
  transactionId: Scalars['ID'];
};

export type CancelBookingArgs = {
  /** The uuid of the booking. */
  uuid: Scalars['ID'];
};

export type CancelBookingResponse = {
  __typename?: 'CancelBookingResponse';
  /** The uuid of the booking. */
  id: Scalars['String'];
};

export type Chauffeur = {
  __typename?: 'Chauffeur';
  /** Chauffeur ID */
  id?: Maybe<Scalars['ID']>;
  /** Chauffeur first name */
  firstName: Scalars['String'];
  /** Chauffeur last name */
  lastName: Scalars['String'];
  /** Phone number of the Chauffeur // what is the format? */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Chauffeur LSP's UUID. */
  companyId?: Maybe<Scalars['ID']>;
};

export type ChauffeurAvailabilityResponse = {
  __typename?: 'ChauffeurAvailabilityResponse';
  available: Scalars['Boolean'];
};

/** The possible grant types for a chauffeur. */
export enum ChauffeurGrantType {
  Implicit = 'implicit'
}

export type ChauffeurLoginError = {
  __typename?: 'ChauffeurLoginError';
  message: Scalars['String'];
  description: Scalars['String'];
};

export type ChauffeurLoginResponse = {
  __typename?: 'ChauffeurLoginResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<ChauffeurLoginError>>>;
  accessToken?: Maybe<AccessToken>;
};

export type ChauffeurLogoutError = {
  __typename?: 'ChauffeurLogoutError';
  message: Scalars['String'];
  description: Scalars['String'];
};

export type ChauffeurLogoutResponse = {
  __typename?: 'ChauffeurLogoutResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<ChauffeurLogoutError>>>;
};

/** The possible resource owner types. */
export enum ChauffeurOwnerType {
  Driver = 'driver'
}

export type ChauffeurPositionEvent = {
  __typename?: 'ChauffeurPositionEvent';
  rideUuid: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  accuracy: Scalars['Float'];
  trackedAt: Scalars['String'];
  speed?: Maybe<Scalars['Float']>;
  speedAccuracy?: Maybe<Scalars['Float']>;
  bearing?: Maybe<Scalars['Float']>;
};

/** Response for ChauffeurPosition request */
export type ChauffeurPositionResponse = {
  __typename?: 'ChauffeurPositionResponse';
  /** Unique ID of the position request. */
  id: Scalars['ID'];
};

export type ChauffeurPositionSubscriptionInput = {
  riduUuid: Scalars['ID'];
};

/** The possible statuses for a Chauffeur. */
export enum ChauffeurStatus {
  /** Chauffeur claim an offer. (Right after, (s)he receives confirmation that offer was accepted and drive to pickup location) */
  OfferClaimed = 'OFFER_CLAIMED',
  /** Chauffeur arrives at the pickup location. */
  Arrived = 'ARRIVED',
  /** Chauffeur picks up the guest(s) and drives to the drop-off location */
  PickedUpGuest = 'PICKED_UP_GUEST',
  /** The ride has been finished. Passenger off boards the car. */
  Finished = 'FINISHED'
}

export type ChauffeurStatusUpdateResponse = {
  __typename?: 'ChauffeurStatusUpdateResponse';
  success: Scalars['Boolean'];
};

/** ChauffeurUser */
export type ChauffeurUser = {
  __typename?: 'ChauffeurUser';
  uuid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  defaultVehicle?: Maybe<DefaultVehicle>;
};

export type ClaimedOfferSubscriptionResponse = {
  __typename?: 'ClaimedOfferSubscriptionResponse';
  offerUuid: Scalars['ID'];
  rideUuid: Scalars['ID'];
};

export type ClaimOfferResponse = {
  __typename?: 'ClaimOfferResponse';
  /** ID of the acceptance request. */
  id?: Maybe<Scalars['ID']>;
  /** Validation errors (if any). */
  errors?: Maybe<Array<Maybe<AcceptOfferError>>>;
};

/** A latitude and longitude pair. */
export type Coordinates = {
  /** Longitude of the coordinate */
  longitude: Scalars['Float'];
  /** Latitude of the coordinate */
  latitude: Scalars['Float'];
};

/** Role of a corporate employee in the corporation */
export enum CorporateRole {
  CorporationMember = 'corporation_member',
  CorporationBooker = 'corporation_booker',
  CorporationSuperBooker = 'corporation_super_booker',
  CorporationAdmin = 'corporation_admin'
}

export type Corporation = {
  __typename?: 'Corporation';
  /** Shows if the reference code is mandatory */
  isReferenceRequired: Scalars['Boolean'];
  /** Shows if the reference code needs to be validated */
  validateReference: Scalars['Boolean'];
};

export type CreateCorporationInviteResult = {
  __typename?: 'CreateCorporationInviteResult';
  results: Array<Scalars['String']>;
};

/** A range between two an ISO-8601 encoded date strings */
export type DateRange = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


/** Default Vehicle for Chaffeur (ondemand) */
export type DefaultVehicle = {
  __typename?: 'DefaultVehicle';
  id: Scalars['ID'];
  model: Scalars['String'];
  plate: Scalars['String'];
  serviceClass: OnDemandServiceClass;
};

export type DeviceRegistrationResponse = {
  __typename?: 'DeviceRegistrationResponse';
  success: Scalars['Boolean'];
};

/** Information about a corporate employee */
export type Employee = {
  __typename?: 'Employee';
  uuid: Scalars['String'];
  title: Title;
  companyName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  landline?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  streetAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  corporateRole?: Maybe<CorporateRole>;
  roles: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  tosAcceptedAt?: Maybe<Scalars['String']>;
};

/** Return type of the employees query */
export type EmployeesPayload = {
  __typename?: 'EmployeesPayload';
  items: Array<Employee>;
  pageInfo: PageInfo;
};

export type GuestInput = {
  id?: Maybe<Scalars['ID']>;
  title: Title;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

/** GuestUser */
export type GuestUser = {
  __typename?: 'GuestUser';
  uuid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  locale: Scalars['String'];
  corporationName?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

/** HourlyRide input details for a booking. */
export type HourlyRideInput = {
  /** Identifier for the pickup location. */
  pickupPlaceId: Scalars['String'];
  /** Ride duration. */
  duration: Scalars['Int'];
  /** Pickup time for pre-booked rides. */
  pickupTime: Scalars['DateTime'];
  /** Passengers. */
  passengers: Array<PassengerInput>;
  /** Vehicle. */
  vehicle: VehicleInput;
  /** Pickup sign for the passenger. */
  pickupSign?: Maybe<Scalars['String']>;
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Pickup flight designator in case of transfer from/to airport. */
  pickupFlightDesignator?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Invoice number */
  number?: Maybe<Scalars['String']>;
  /** Company name */
  companyName?: Maybe<Scalars['String']>;
  title?: Maybe<Title>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Street address */
  address?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Zip / postal code */
  zipCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** URL to download the invoice */
  downloadLink?: Maybe<Scalars['String']>;
};

export type InvoiceInput = {
  companyName?: Maybe<Scalars['String']>;
  title?: Maybe<Title>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

/** Location details. */
export type Location = {
  __typename?: 'Location';
  /**
   * Place Id generated by Atlas (eg. U:Nzk5OWIxZTktZDI0Yy00OTMzLWI3ODAtMzY5OGYyMzcxNmYy)
   * // TODO: they should be required but breaks in resolver
   */
  placeId?: Maybe<Scalars['String']>;
  /**
   * Latitude. eg. 52.554963
   * // TODO: they should be required but breaks in resolver
   */
  latitude?: Maybe<Scalars['String']>;
  /**
   * longitude. eg. 13.289878
   * // TODO: they should be required but breaks in resolver
   */
  longitude?: Maybe<Scalars['String']>;
  /** Address name. (eg. Hotel Berlin) */
  name?: Maybe<Scalars['String']>;
  /** Complete address. (eg. Unter den Linden 77, 10117 Berlin, Mitte) */
  address: Scalars['String'];
  /** IATA Airport Code - identifier of an airport (e.g. BER) */
  airportIata?: Maybe<Scalars['String']>;
};

/**
 * Location information of the pickup or dropoff
 * For preBooked rides: either placeId or latitude and longitude is required
 * For Ondemand: all elements are required
 */
export type LocationInput = {
  /** Longitude of the location */
  longitude?: Maybe<Scalars['Float']>;
  /** Latitude of the location */
  latitude?: Maybe<Scalars['Float']>;
  /** Location placeID */
  placeId?: Maybe<Scalars['String']>;
  /** Address */
  address?: Maybe<Scalars['String']>;
};

/** LocationOutput containing latitude and longitude. */
export type LocationOutput = {
  __typename?: 'LocationOutput';
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

/** The possible mutations. */
export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  toggleAvailability?: Maybe<ToggleAvailabilityResponse>;
  /** Authenticate and create access token or a chauffeur. */
  chauffeurLogin: ChauffeurLoginResponse;
  /** Logout chauffeur from the app. */
  chauffeurLogout: ChauffeurLogoutResponse;
  setDefaultVehicle?: Maybe<SetDefaultVehicleResponse>;
  chauffeurStatusUpdate?: Maybe<ChauffeurStatusUpdateResponse>;
  createBooking: BookingResponse;
  updateBooking: BookingUpdateResponse;
  cancelBooking: CancelBookingResponse;
  createChauffeurPosition: ChauffeurPositionResponse;
  /** Registers device for on-demand push notifications */
  registerDevice?: Maybe<DeviceRegistrationResponse>;
  /**
   * Send an invitation email to a list of users with a link that allows
   * the users to register accounts as a corporate employee.
   */
  createCorporationInvite?: Maybe<CreateCorporationInviteResult>;
  deleteEmployee?: Maybe<Scalars['Void']>;
  /**
   * If a user with given email exists, add the user to the corporation of the
   * caller.  If a user with given email doesn't exist, create a user and add
   * the user to the corporation.
   * This mutation can only be invoked by a corporation admin.
   */
  addCorporationEmployee: Employee;
  acceptOffer?: Maybe<AcceptOfferResponse>;
  claimOffer?: Maybe<ClaimOfferResponse>;
  testSubscription: TestSubscriptionMutationResponse;
  updateUser: GuestUser;
};


/** The possible mutations. */
export type MutationToggleAvailabilityArgs = {
  availability: Scalars['Boolean'];
};


/** The possible mutations. */
export type MutationChauffeurLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  clientId: Scalars['String'];
  grantType: ChauffeurGrantType;
  resourceOwnerType: ChauffeurOwnerType;
};


/** The possible mutations. */
export type MutationSetDefaultVehicleArgs = {
  vehicleUuid: Scalars['ID'];
  serviceClass: OnDemandServiceClass;
};


/** The possible mutations. */
export type MutationChauffeurStatusUpdateArgs = {
  rideUuid: Scalars['ID'];
  chauffeurStatus: ChauffeurStatus;
};


/** The possible mutations. */
export type MutationCreateBookingArgs = {
  nonce: Scalars['ID'];
  deviceData: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentInput>;
  services: ServicesInput;
  invoice?: Maybe<InvoiceInput>;
};


/** The possible mutations. */
export type MutationUpdateBookingArgs = {
  id: Scalars['ID'];
  transactionId: Scalars['ID'];
  invoice?: Maybe<InvoiceInput>;
  services: ServicesUpdateInput;
};


/** The possible mutations. */
export type MutationCancelBookingArgs = {
  booking?: Maybe<CancelBookingArgs>;
};


/** The possible mutations. */
export type MutationCreateChauffeurPositionArgs = {
  accuracy: Scalars['Float'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  trackedAt: Scalars['String'];
  speed?: Maybe<Scalars['Float']>;
  speedAccuracy?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  bearing?: Maybe<Scalars['Float']>;
  rideUuid?: Maybe<Scalars['ID']>;
};


/** The possible mutations. */
export type MutationRegisterDeviceArgs = {
  deviceToken: Scalars['String'];
  pushProvider: PushProvider;
};


/** The possible mutations. */
export type MutationCreateCorporationInviteArgs = {
  emails: Array<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


/** The possible mutations. */
export type MutationDeleteEmployeeArgs = {
  uuid: Scalars['String'];
};


/** The possible mutations. */
export type MutationAddCorporationEmployeeArgs = {
  title: Title;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobile: Scalars['String'];
};


/** The possible mutations. */
export type MutationAcceptOfferArgs = {
  offerUuid: Scalars['ID'];
};


/** The possible mutations. */
export type MutationClaimOfferArgs = {
  offerUuid: Scalars['ID'];
};


/** The possible mutations. */
export type MutationUpdateUserArgs = {
  accountNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  defaultPaymentMethodUuid?: Maybe<Scalars['String']>;
  distanceUnit?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  landline?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  title?: Maybe<Title>;
  wantsEmailNotification?: Maybe<Scalars['Boolean']>;
  wantsNewsletter?: Maybe<Scalars['Boolean']>;
  wantsPushNotification?: Maybe<Scalars['Boolean']>;
  wantsSmsNotification?: Maybe<Scalars['Boolean']>;
};

export type NearbyChauffeursResponse = {
  __typename?: 'NearbyChauffeursResponse';
  locations: Array<LocationOutput>;
  eta_seconds: Scalars['Int'];
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['ID'];
  price: OfferPrice;
  service: Ride;
};

export type OfferAcceptResponse = {
  __typename?: 'OfferAcceptResponse';
  offerUuid: Scalars['ID'];
  accepted: Scalars['Boolean'];
  rejectionReason?: Maybe<Scalars['String']>;
  eventType: Scalars['String'];
};

export type OfferPrice = {
  __typename?: 'OfferPrice';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type OfferSubscriptionResponse = OfferUpdateResponse | OfferAcceptResponse;

export type OfferUpdateResponse = {
  __typename?: 'OfferUpdateResponse';
  eventType: Scalars['String'];
};

/** OnDemandRide input details for a booking. */
export type OnDemandRideInput = {
  /** Identifier for the pickup location. */
  pickupPlaceId: Scalars['String'];
  /** Identifier for the drop-off location. */
  dropoffPlaceId: Scalars['String'];
  /** Pickup time for pre-booked rides. */
  pickupTime: Scalars['DateTime'];
};

/** The type of Vehicle (ondemand) */
export enum OnDemandServiceClass {
  /** Business Class */
  Business = 'business',
  /** First Class */
  First = 'first'
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Ordering = {
  field: Scalars['String'];
  direction: OrderDirection;
};

/**
 *  TODO: prevPageOffset, nextPageOffset, currentOffset, itemsPerPage
 * will be deprecated as soon Pagination component in DS is used in all FE apps
 * and calculation here is not needed
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Returns total pages based on the limit and total count */
  totalPages: Scalars['Int'];
  /** Returns total number of the items of the result */
  totalCount: Scalars['Int'];
  /** Returns the offset for the previous page */
  prevPageOffset?: Maybe<Scalars['Int']>;
  /** Returns the offset for the next page */
  nextPageOffset?: Maybe<Scalars['Int']>;
  /** Returns the current offset */
  currentOffset?: Maybe<Scalars['Int']>;
  /** Effective number of items per page */
  itemsPerPage?: Maybe<Scalars['Int']>;
};

/** Passenger details. */
export type Passenger = {
  __typename?: 'Passenger';
  /** Passenger title */
  title?: Maybe<Title>;
  /** Passenger first name. */
  firstName: Scalars['String'];
  /** Passenger last name. */
  lastName: Scalars['String'];
  /** Passenger email address */
  email: Scalars['String'];
  /** Passenger phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PassengerInput = {
  /** Passenger title */
  title?: Maybe<Title>;
  /** Passenger first name. */
  firstName: Scalars['String'];
  /** Passenger last name. */
  lastName: Scalars['String'];
  /** Passenger email address */
  email: Scalars['String'];
  /** Passenger phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  /** Type of payment used - private / business credit card, invoice */
  paymentMethodType: PaymentMethodType;
  /** Information whether the amount was already paid or waits for processing */
  paid: Scalars['Boolean'];
  chargedDate?: Maybe<Scalars['DateTime']>;
  /** Last 4 digits of credit card used, e.g. "1111" */
  creditCardLastDigits?: Maybe<Scalars['String']>;
  /** Credit card holder (e.g. Visa, MasterCard, AMEX, Generic) */
  holder?: Maybe<Scalars['String']>;
  /** Credit card issuer */
  issuer?: Maybe<Scalars['String']>;
  /** Credit card expiration date in MM/YYYY format, e.g. "12/2031" */
  creditCardExpiration?: Maybe<Scalars['String']>;
  /** Payment Method Uuid */
  paymentMethodUuid: Scalars['String'];
};

export type PaymentInput = {
  /** Unique ID of the payment method */
  uuid?: Maybe<Scalars['ID']>;
  /** Device data used for fraud detection. */
  deviceData?: Maybe<Scalars['String']>;
  /** Payment Verification Details */
  verificationDetails?: Maybe<PaymentVerificationDetailsInput>;
};

export enum PaymentMethodType {
  PrivateCreditCard = 'PRIVATE_CREDIT_CARD',
  BusinessCreditCard = 'BUSINESS_CREDIT_CARD',
  CollectiveInvoice = 'COLLECTIVE_INVOICE',
  Paypal = 'PAYPAL'
}

export type PaymentVerificationDetailsInput = {
  nonce?: Maybe<Scalars['String']>;
  /** Indicates whether the card was eligible for 3D Secure. */
  liabilityShiftPossible?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the liability for fraud has been shifted away from the merchant. */
  liabilityShifted?: Maybe<Scalars['Boolean']>;
  /** Unique transaction identifier assigned by the Access Control Server (ACS) to identify a single transaction. */
  acsTransactionId?: Maybe<Scalars['String']>;
  /** Transaction identifier resulting from 3D Secure 2 authentication. */
  dsTransactionId?: Maybe<Scalars['String']>;
  /**
   * Cardholder authentication verification value or CAVV.
   * The main encrypted message issuers and card networks use to verify authentication has occurred.
   * Mastercard uses an AVV message and American Express uses an AEVV message,
   * each of which should also be passed in the cavv parameter.
   */
  cavv?: Maybe<Scalars['String']>;
  /**
   * Indicates whether a card is enrolled in a 3D Secure program or not.
   * Possible values: Y = Yes N = No U = Unavailable B = Bypass E = RequestFailure
   */
  enrolled?: Maybe<Scalars['String']>;
  /** The 3D Secure status value. */
  status?: Maybe<Scalars['String']>;
  /** Unique transaction identifier assigned by the 3DS Server to identify a single transaction. */
  threeDSecureServerTransactionId?: Maybe<Scalars['String']>;
  /** The version of 3D Secure authentication used for the transaction. */
  threeDSecureVersion?: Maybe<Scalars['String']>;
  /**
   * Transaction identifier resulting from 3D Secure authentication.
   * Uniquely identifies the transaction and sometimes required in the authorization message.
   * This is a base64-encoded value. This field will no longer be used
   * in 3D Secure 2 authentications for Visa and Mastercard,
   * however it will be supported by American Express.
   */
  xid?: Maybe<Scalars['String']>;
};

export type PossibleServiceClass = {
  __typename?: 'PossibleServiceClass';
  serviceClass: ServiceClass;
  /** Max luggage that is allowed for this tier */
  maxLuggage: Scalars['Int'];
  /** Max number of guests allowed for this tier */
  maxGuests: Scalars['Int'];
  price: Price;
};

/** Price information for a specific vehicle type */
export type Price = {
  __typename?: 'Price';
  /** Internal reference uuid for the pricing rules used on the generation of the price */
  pricingUuid: Scalars['ID'];
  /**
   * Vehicle type is the service class
   * @deprecated Price type should only have price related fields.
   */
  vehicleType: ServiceClass;
  /** Currency of the price */
  currency: Scalars['String'];
  /** Correct Tax Rate as a float */
  taxRateDecimal?: Maybe<Scalars['Float']>;
  /**
   * Tax rate
   * @deprecated taxRate is deprecated due to rounding issues. Use taxRateDecimal instead.
   */
  taxRate?: Maybe<Scalars['Int']>;
  /** Price details breakdown */
  priceItems: Array<PriceItem>;
  /** Net amount */
  netAmount: Scalars['Int'];
  /** Tax amount */
  taxAmount: Scalars['Int'];
  /** Total amount */
  totalAmount: Scalars['Int'];
  /** Decimal separator (precision dot) */
  decimalSeparator: Scalars['Int'];
};

/** Price details */
export type PriceItem = {
  __typename?: 'PriceItem';
  /** Type of the price item (e.g: extra_fee, base_price, ...) */
  type: Scalars['String'];
  /** Net amount of the item */
  netAmount: Scalars['Int'];
  /** Currency for that give item, e.g. EUR, USD */
  currency?: Maybe<Scalars['String']>;
  /** NetAmount + Vat (Tax) */
  totalAmount?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  /** Rate for tax used, e.g. 19 */
  taxRate?: Maybe<Scalars['Int']>;
};

/** Results of the price discovery */
export type PriceResults = {
  __typename?: 'PriceResults';
  results?: Maybe<Array<Price>>;
};

/** The possible serviceType for price discovery. */
export enum PriceServiceType {
  All = 'all',
  Ondemand = 'ondemand',
  Transfer = 'transfer',
  Hourly = 'hourly'
}

export enum PushProvider {
  /** Push provider for Android devices */
  Fcm = 'FCM',
  /** Push provider for Apple devices */
  Apns = 'APNS'
}

/** The possible queries. */
export type Query = {
  __typename?: 'Query';
  /** Fetch bookings for the customer. */
  bookings: BookingsPayload;
  /** Fetch single booking for the customer. */
  booking: BookingPayload;
  /** Fetch auto suggest results. */
  autosuggest: AutoSuggestions;
  /** Fetch information about currently logged in user. */
  me?: Maybe<User>;
  /** Fetch the prices for all the ServiceClasses for given route */
  price?: Maybe<PriceResults>;
  /** Query availability for services */
  serviceAvailability?: Maybe<Array<Maybe<ServiceAvailabilityResult>>>;
  /** Fetch list of possible service classes options and their prices */
  serviceClasses: Array<Maybe<PossibleServiceClass>>;
  /** Fetch list of employees for current organization */
  employees?: Maybe<EmployeesPayload>;
  /** Returns a boolean which indicates whether the chauffeurs is available. */
  chauffeurAvailability?: Maybe<ChauffeurAvailabilityResponse>;
  listDefaultVehicles: Array<Maybe<DefaultVehicle>>;
  nearbyChauffeurs?: Maybe<NearbyChauffeursResponse>;
  /**
   * FIXME: We should receive the OAuth token in the header, and resolve the UUID
   * of the chauffeur from it. Waiting for auth logic to be implemented.
   */
  offers: Array<Maybe<Offer>>;
  ride?: Maybe<Ride>;
  rides?: Maybe<Array<Ride>>;
};


/** The possible queries. */
export type QueryBookingsArgs = {
  filter: BookingFilters;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<OrderDirection>;
};


/** The possible queries. */
export type QueryBookingArgs = {
  bookingUuid: Scalars['ID'];
};


/** The possible queries. */
export type QueryAutosuggestArgs = {
  suggestLocation: Scalars['String'];
};


/** The possible queries. */
export type QueryPriceArgs = {
  pickup: LocationInput;
  dropoff?: Maybe<LocationInput>;
  departAt: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  serviceType?: Maybe<PriceServiceType>;
  corporationUuid?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
  externalAgentCode?: Maybe<Scalars['String']>;
  bookingUuid?: Maybe<Scalars['String']>;
};


/** The possible queries. */
export type QueryServiceAvailabilityArgs = {
  services: ServiceAvailability;
};


/** The possible queries. */
export type QueryServiceClassesArgs = {
  pickup: LocationInput;
  dropoff?: Maybe<LocationInput>;
  departAt: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  serviceType: PriceServiceType;
  corporationUuid?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  voucherCode?: Maybe<Scalars['String']>;
  externalAgentCode?: Maybe<Scalars['String']>;
  bookingUuid?: Maybe<Scalars['String']>;
};


/** The possible queries. */
export type QueryEmployeesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Ordering>;
  search?: Maybe<Scalars['String']>;
};


/** The possible queries. */
export type QueryNearbyChauffeursArgs = {
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};


/** The possible queries. */
export type QueryRideArgs = {
  id: Scalars['ID'];
};


/** The possible queries. */
export type QueryRidesArgs = {
  filter: RideFilters;
};

export type Rating = {
  __typename?: 'Rating';
  rate?: Maybe<Scalars['Float']>;
  isRateable: Scalars['Boolean'];
  vehicleRating?: Maybe<Scalars['Int']>;
  chauffeurRating?: Maybe<Scalars['Int']>;
};

export type Ride = Service & {
  __typename?: 'Ride';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Booking details. */
  booking: Booking;
  /** Passengers details. */
  passengers: Array<Passenger>;
  /** Pickup location. */
  pickup: Location;
  /** Dropoff location. */
  dropoff?: Maybe<Location>;
  /** Ride date. */
  date?: Maybe<Scalars['DateTime']>;
  /** Ride status. // TODO: this will be required */
  status?: Maybe<RideStatus>;
  /** Status update date. */
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  /**
   * Booking number
   * @deprecated  Use `number` in the Booking Object.
   */
  bookingNumber?: Maybe<Scalars['String']>;
  /** The distance of the ride (in meters). */
  distance?: Maybe<Scalars['Int']>;
  /** The duration of the ride (in seconds). */
  duration?: Maybe<Scalars['Int']>;
  /** Flight number e.g. EUJ 4676 */
  pickupFlightDesignator?: Maybe<Scalars['String']>;
  /** Shows if service is business or personal */
  serviceScope: ServiceScope;
  /** Shows if ride is a ondemand, transfer or hourly */
  category: RideCategory;
  /** Details about the voucher used, if any */
  voucher?: Maybe<Voucher>;
  reference?: Maybe<Scalars['String']>;
  /** The vehicle details */
  vehicle?: Maybe<Vehicle>;
  /** The chauffeur details */
  chauffeur?: Maybe<Chauffeur>;
  /** Pickup Sign */
  pickupSign?: Maybe<Scalars['String']>;
  /** Additional notes */
  notes?: Maybe<Scalars['String']>;
  /** Status update history */
  statusHistory?: Maybe<Array<RideStatusHistory>>;
  /**
   * Time in seconds that chauffeur will be waiting Guest
   * After this time, extra charges could be applied
   */
  pickupMaxWaitingTime?: Maybe<Scalars['Int']>;
  /** Initial pickup ETA */
  initialPickupETA?: Maybe<Scalars['Int']>;
  /** Ride rating, average of driver and car rating */
  rating: Rating;
  /** Chauffeur rating */
  chauffeurRating?: Maybe<Scalars['Int']>;
  /** Car rating */
  carRating?: Maybe<Scalars['Int']>;
  /** Information if ride is a long distance */
  longDistance?: Maybe<Scalars['Boolean']>;
  /** Polyline path for map */
  polylinePath?: Maybe<Scalars['String']>;
  /** Information if ride can still be edited */
  editable?: Maybe<Scalars['Boolean']>;
  /** Information if ride can still be canceled */
  cancelable?: Maybe<Scalars['Boolean']>;
  /** Estimated cost of cancelation */
  estimatedCancelationFee?: Maybe<Scalars['Float']>;
  /** Third party identifier, e.g. Emirates */
  thirdPartySource?: Maybe<Scalars['String']>;
  /** Uuid of corporation booking the ride */
  corporationUuid?: Maybe<Scalars['String']>;
  /** Nonce identifying the ride */
  nonce?: Maybe<Scalars['String']>;
  /** Cause for rejection/denial */
  cause?: Maybe<Scalars['String']>;
  /** SubCause for rejection/denial. Provides more fine-grained details for the cause */
  subCause?: Maybe<Scalars['String']>;
};

/** Ride service input for availability check */
export type RideAvailability = {
  /** Category of the ride e.g Ondemand */
  category: RideCategory;
  /** Pickup of the ride */
  pickup: Coordinates;
};

/** Result of ride service */
export type RideAvailabilityResult = {
  __typename?: 'RideAvailabilityResult';
  /** Category of the service e.g ondemand */
  category?: Maybe<RideCategory>;
  /** pickup coordinates */
  pickup: LocationOutput;
  /** Availability flag */
  available: Scalars['Boolean'];
};

/** The possible serviceType for bookings. */
export enum RideCategory {
  All = 'all',
  Ondemand = 'ondemand',
  Transfer = 'transfer',
  Hourly = 'hourly'
}

/** The possible filters for rides. */
export type RideFilters = {
  /** Progress of the ride */
  progress?: Maybe<RideProgress>;
};

/** Ride input details for a booking. */
export type RideInput = {
  /** Category of the ride */
  category: RideCategory;
  /** Identifier for the pickup location. */
  pickupPlaceId: Scalars['String'];
  /** Identifier for the drop-off location. */
  dropoffPlaceId: Scalars['String'];
  /** Pickup time for pre-booked rides. */
  pickupTime: Scalars['DateTime'];
};

/** The progress of the ride, based on its status */
export enum RideProgress {
  /**
   * The ride is active
   * one of the following statuses: new, accepted, chf_on_the_way, chf_arrived, pax_on_board
   */
  Active = 'active',
  /** The finished rides */
  Finished = 'finished'
}

export enum RideStatus {
  Draft = 'draft',
  New = 'new',
  Accepted = 'accepted',
  ChfOnTheWay = 'chf_on_the_way',
  ChfArrived = 'chf_arrived',
  PaxOnBoard = 'pax_on_board',
  Finished = 'finished',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Denied = 'denied'
}

export type RideStatusHistory = {
  __typename?: 'RideStatusHistory';
  status: RideStatus;
  occurredAt: Scalars['DateTime'];
};

export type RideStatusUpdated = {
  __typename?: 'RideStatusUpdated';
  id: Scalars['ID'];
  status: RideStatus;
  cause?: Maybe<Scalars['String']>;
  subCause?: Maybe<Scalars['String']>;
};

export type RideUpdateInput = {
  /** Ride uuid */
  id: Scalars['ID'];
  category: RideCategory;
  pickupPlaceId?: Maybe<Scalars['String']>;
  dropoffPlaceId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  passengers?: Maybe<Array<GuestInput>>;
  vehicle?: Maybe<VehicleInput>;
  duration?: Maybe<Scalars['Int']>;
  pickupSign?: Maybe<Scalars['String']>;
  pickupFlightDesignator?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** Only setting to 'Canceled' is supported currently */
  status?: Maybe<RideStatus>;
};

export type Service = {
  /** Unique ID. */
  id: Scalars['ID'];
  /** Booking details. */
  booking: Booking;
};

/** Service input for availability check */
export type ServiceAvailability = {
  /** ride service */
  rides?: Maybe<Array<Maybe<RideAvailability>>>;
};

/** Service Availability Result */
export type ServiceAvailabilityResult = RideAvailabilityResult;

/** The type of Vehicle */
export enum ServiceClass {
  /** Business Class */
  BusinessClass = 'business_class',
  /** Eco (e.g. Toyota Camry, Honda Accord, Toyota Corolla) */
  Eco = 'eco',
  /** First Class */
  FirstClass = 'first_class',
  /** Business Van */
  BusinessVan = 'business_van',
  /** Smart */
  Smart = 'smart',
  /** Green (e.g. Tesla Model S, Tesla Model X) */
  Green = 'green',
  /** SUV */
  Suv = 'suv',
  /** Electric (e.g. Jaguar I-PACE) */
  ElectricClass = 'electric_class',
  /** Sprinter Class (e.g. Mercedes Sprinter) */
  SprinterClass = 'sprinter_class'
}

/** Information if service is business or personal */
export enum ServiceScope {
  /** All the ride types below. */
  All = 'all',
  /** B2B */
  Business = 'business',
  /** B2C */
  Personal = 'personal'
}

export type ServicesInput = {
  rides?: Maybe<Array<RideInput>>;
  onDemandRides?: Maybe<Array<OnDemandRideInput>>;
  transferRides?: Maybe<Array<TransferRideInput>>;
  hourlyRides?: Maybe<Array<HourlyRideInput>>;
};

export type ServicesUpdateInput = {
  rides?: Maybe<Array<RideUpdateInput>>;
};

export enum ServiceType {
  All = 'all',
  Ride = 'ride'
}

export type SetDefaultVehicleError = {
  __typename?: 'SetDefaultVehicleError';
  message: Scalars['String'];
  description: Scalars['String'];
};

export type SetDefaultVehicleResponse = {
  __typename?: 'SetDefaultVehicleResponse';
  success: Scalars['Boolean'];
};

/** The possible subscriptions. */
export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  bookingUpdates: SubscriptionResponse;
  /** This is the subscription on which a chauffeur's live location will be published */
  chauffeurPositionUpdates?: Maybe<ChauffeurPositionEvent>;
  /**
   * This is the subscription on which a chauffeur can receive
   * an offer update or offer acceptance event
   */
  offerEvents: OfferSubscriptionResponse;
  /**
   * This is the subscription on which a chauffeur can receive
   * a claimed offer cancelation event
   */
  claimedOfferEvents: ClaimedOfferSubscriptionResponse;
  testSubscription: TestSubscriptionResponse;
};


/** The possible subscriptions. */
export type SubscriptionBookingUpdatesArgs = {
  transactionId?: Maybe<Scalars['ID']>;
};


/** The possible subscriptions. */
export type SubscriptionChauffeurPositionUpdatesArgs = {
  rideUuid: Scalars['ID'];
};

export type SubscriptionResponse = BookingUpdated | BookingStatusUpdated | RideStatusUpdated;

export type TestSubscriptionMutationResponse = {
  __typename?: 'TestSubscriptionMutationResponse';
  success: Scalars['Boolean'];
};

export type TestSubscriptionResponse = {
  __typename?: 'TestSubscriptionResponse';
  message: Scalars['String'];
};

export enum TimeState {
  /** Filter user bookings by the past rides */
  Past = 'past',
  /** Filter user bookings by the upcoming rides */
  Upcoming = 'upcoming',
  /** Filter for all rides containing past & upcoming */
  All = 'all'
}

export enum Title {
  Mr = 'mr',
  Ms = 'ms',
  Unknown = 'unknown'
}

export type ToggleAvailabilityResponse = {
  __typename?: 'ToggleAvailabilityResponse';
  success: Scalars['Boolean'];
};

/** TransferRide input details for a booking. */
export type TransferRideInput = {
  /** Identifier for the pickup location. */
  pickupPlaceId: Scalars['String'];
  /** Identifier for the drop-off location. */
  dropoffPlaceId: Scalars['String'];
  /** Pickup time for pre-booked rides. */
  pickupTime: Scalars['DateTime'];
  /** Passengers. */
  passengers: Array<PassengerInput>;
  /** Vehicle. */
  vehicle: VehicleInput;
  /** Pickup sign for the passenger. */
  pickupSign?: Maybe<Scalars['String']>;
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Pickup flight designator in case of transfer from/to airport. */
  pickupFlightDesignator?: Maybe<Scalars['String']>;
};

export type UpdateError = {
  __typename?: 'UpdateError';
  message: Scalars['String'];
  description: Scalars['String'];
};

export type User = AdminUser | ChauffeurUser | GuestUser;

/** Vehicle details. */
export type Vehicle = {
  __typename?: 'Vehicle';
  /** The type of vehicle */
  class?: Maybe<ServiceClass>;
  /** Max seats allowed */
  seats?: Maybe<Scalars['Int']>;
  /** Max luggages allowed */
  luggage?: Maybe<Scalars['Int']>;
  /** Model of Vehicle */
  model?: Maybe<Scalars['String']>;
  /** Plate of Vehicle */
  plate?: Maybe<Scalars['String']>;
};

export type VehicleInput = {
  class: ServiceClass;
};


export type Voucher = {
  __typename?: 'Voucher';
  code?: Maybe<Scalars['String']>;
  /** Value of voucher. It can be an amount or a percentage */
  value?: Maybe<Scalars['String']>;
};

export type BookingDetailsQueryVariables = Exact<{
  bookingUuid: Scalars['ID'];
}>;


export type BookingDetailsQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'BookingPayload' }
    & { item?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'number'>
      & { booker: (
        { __typename?: 'Booker' }
        & Pick<Booker, 'uuid'>
      ), price: (
        { __typename?: 'Price' }
        & Pick<Price, 'currency' | 'netAmount' | 'taxAmount' | 'totalAmount'>
      ), services: Array<(
        { __typename?: 'Ride' }
        & Pick<Ride, 'status' | 'statusUpdatedAt' | 'date' | 'distance' | 'duration' | 'serviceScope' | 'category'>
        & { statusHistory?: Maybe<Array<(
          { __typename?: 'RideStatusHistory' }
          & Pick<RideStatusHistory, 'status' | 'occurredAt'>
        )>>, passengers: Array<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
        )>, pickup: (
          { __typename?: 'Location' }
          & Pick<Location, 'name' | 'latitude' | 'longitude' | 'address'>
        ), dropoff?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'name' | 'address'>
        )>, chauffeur?: Maybe<(
          { __typename?: 'Chauffeur' }
          & Pick<Chauffeur, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'companyId'>
        )>, rating: (
          { __typename?: 'Rating' }
          & Pick<Rating, 'rate'>
        ) }
      )> }
    )> }
  ) }
);

export type BookingListQueryVariables = Exact<{
  timeState: TimeState;
  pageSize: Scalars['Int'];
  offset: Scalars['Int'];
  serviceScope: ServiceScope;
  status?: Maybe<Array<BookingStatus> | BookingStatus>;
  dateRange?: Maybe<DateRange>;
  serviceType: ServiceType;
  category: RideCategory;
  bookingNumber?: Maybe<Scalars['String']>;
  matching?: Maybe<Scalars['String']>;
}>;


export type BookingListQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingsPayload' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'totalPages' | 'totalCount' | 'currentOffset' | 'prevPageOffset' | 'nextPageOffset'>
    ), items: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'number'>
      & { booker: (
        { __typename?: 'Booker' }
        & Pick<Booker, 'uuid'>
      ), price: (
        { __typename?: 'Price' }
        & Pick<Price, 'netAmount' | 'totalAmount' | 'currency'>
      ), services: Array<(
        { __typename?: 'Ride' }
        & Pick<Ride, 'status' | 'date' | 'distance' | 'duration' | 'serviceScope' | 'category'>
        & { passengers: Array<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'firstName' | 'lastName' | 'phoneNumber'>
        )>, chauffeur?: Maybe<(
          { __typename?: 'Chauffeur' }
          & Pick<Chauffeur, 'firstName' | 'lastName' | 'phoneNumber' | 'companyId'>
        )>, pickup: (
          { __typename?: 'Location' }
          & Pick<Location, 'name' | 'address' | 'latitude' | 'longitude'>
        ), dropoff?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'name' | 'address'>
        )> }
      )> }
    )> }
  ) }
);

export type AutosuggestQueryVariables = Exact<{
  suggestLocation: Scalars['String'];
}>;


export type AutosuggestQuery = (
  { __typename?: 'Query' }
  & { autosuggest: (
    { __typename?: 'AutoSuggestions' }
    & Pick<AutoSuggestions, 'items'>
  ) }
);


export const BookingDetailsDocument = gql`
    query BookingDetails($bookingUuid: ID!) {
  booking(bookingUuid: $bookingUuid) {
    item {
      id
      status
      number
      booker {
        uuid
      }
      price {
        currency
        netAmount
        taxAmount
        totalAmount
      }
      services {
        ... on Ride {
          status
          statusUpdatedAt
          statusHistory {
            status
            occurredAt
          }
          date
          passengers {
            firstName
            lastName
            email
            phoneNumber
          }
          pickup {
            name
            latitude
            longitude
            address
          }
          dropoff {
            name
            address
          }
          chauffeur {
            id
            firstName
            lastName
            phoneNumber
            companyId
          }
          distance
          duration
          serviceScope
          category
          rating {
            rate
          }
        }
      }
    }
  }
}
    `;
export type BookingDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookingDetailsQuery, BookingDetailsQueryVariables>, 'query'> & ({ variables: BookingDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BookingDetailsComponent = (props: BookingDetailsComponentProps) => (
      <ApolloReactComponents.Query<BookingDetailsQuery, BookingDetailsQueryVariables> query={BookingDetailsDocument} {...props} />
    );
    
export type BookingDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BookingDetailsQuery, BookingDetailsQueryVariables>
    } & TChildProps;
export function withBookingDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookingDetailsQuery,
  BookingDetailsQueryVariables,
  BookingDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BookingDetailsQuery, BookingDetailsQueryVariables, BookingDetailsProps<TChildProps, TDataName>>(BookingDetailsDocument, {
      alias: 'bookingDetails',
      ...operationOptions
    });
};

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      bookingUuid: // value for 'bookingUuid'
 *   },
 * });
 */
export function useBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
      }
export function useBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
        }
export type BookingDetailsQueryHookResult = ReturnType<typeof useBookingDetailsQuery>;
export type BookingDetailsLazyQueryHookResult = ReturnType<typeof useBookingDetailsLazyQuery>;
export type BookingDetailsQueryResult = ApolloReactCommon.QueryResult<BookingDetailsQuery, BookingDetailsQueryVariables>;
export const BookingListDocument = gql`
    query BookingList($timeState: TimeState!, $pageSize: Int!, $offset: Int!, $serviceScope: ServiceScope!, $status: [BookingStatus!], $dateRange: DateRange, $serviceType: ServiceType!, $category: RideCategory!, $bookingNumber: String, $matching: String) {
  bookings(filter: {timeState: $timeState, serviceScope: $serviceScope, status: $status, dateRange: $dateRange, serviceType: $serviceType, category: $category, bookingNumber: $bookingNumber, matching: $matching}, limit: $pageSize, offset: $offset) {
    pageInfo {
      totalPages
      totalCount
      currentOffset
      prevPageOffset
      nextPageOffset
    }
    items {
      id
      status
      number
      booker {
        uuid
      }
      price {
        netAmount
        totalAmount
        currency
      }
      services {
        ... on Ride {
          status
          date
          passengers {
            firstName
            lastName
            phoneNumber
          }
          chauffeur {
            firstName
            lastName
            phoneNumber
            companyId
          }
          pickup {
            name
            address
            latitude
            longitude
          }
          dropoff {
            name
            address
          }
          distance
          duration
          serviceScope
          category
        }
      }
    }
  }
}
    `;
export type BookingListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BookingListQuery, BookingListQueryVariables>, 'query'> & ({ variables: BookingListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BookingListComponent = (props: BookingListComponentProps) => (
      <ApolloReactComponents.Query<BookingListQuery, BookingListQueryVariables> query={BookingListDocument} {...props} />
    );
    
export type BookingListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BookingListQuery, BookingListQueryVariables>
    } & TChildProps;
export function withBookingList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BookingListQuery,
  BookingListQueryVariables,
  BookingListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BookingListQuery, BookingListQueryVariables, BookingListProps<TChildProps, TDataName>>(BookingListDocument, {
      alias: 'bookingList',
      ...operationOptions
    });
};

/**
 * __useBookingListQuery__
 *
 * To run a query within a React component, call `useBookingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingListQuery({
 *   variables: {
 *      timeState: // value for 'timeState'
 *      pageSize: // value for 'pageSize'
 *      offset: // value for 'offset'
 *      serviceScope: // value for 'serviceScope'
 *      status: // value for 'status'
 *      dateRange: // value for 'dateRange'
 *      serviceType: // value for 'serviceType'
 *      category: // value for 'category'
 *      bookingNumber: // value for 'bookingNumber'
 *      matching: // value for 'matching'
 *   },
 * });
 */
export function useBookingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingListQuery, BookingListQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingListQuery, BookingListQueryVariables>(BookingListDocument, baseOptions);
      }
export function useBookingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingListQuery, BookingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingListQuery, BookingListQueryVariables>(BookingListDocument, baseOptions);
        }
export type BookingListQueryHookResult = ReturnType<typeof useBookingListQuery>;
export type BookingListLazyQueryHookResult = ReturnType<typeof useBookingListLazyQuery>;
export type BookingListQueryResult = ApolloReactCommon.QueryResult<BookingListQuery, BookingListQueryVariables>;
export const AutosuggestDocument = gql`
    query Autosuggest($suggestLocation: String!) {
  autosuggest(suggestLocation: $suggestLocation) {
    items
  }
}
    `;
export type AutosuggestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AutosuggestQuery, AutosuggestQueryVariables>, 'query'> & ({ variables: AutosuggestQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AutosuggestComponent = (props: AutosuggestComponentProps) => (
      <ApolloReactComponents.Query<AutosuggestQuery, AutosuggestQueryVariables> query={AutosuggestDocument} {...props} />
    );
    
export type AutosuggestProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AutosuggestQuery, AutosuggestQueryVariables>
    } & TChildProps;
export function withAutosuggest<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AutosuggestQuery,
  AutosuggestQueryVariables,
  AutosuggestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AutosuggestQuery, AutosuggestQueryVariables, AutosuggestProps<TChildProps, TDataName>>(AutosuggestDocument, {
      alias: 'autosuggest',
      ...operationOptions
    });
};

/**
 * __useAutosuggestQuery__
 *
 * To run a query within a React component, call `useAutosuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutosuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutosuggestQuery({
 *   variables: {
 *      suggestLocation: // value for 'suggestLocation'
 *   },
 * });
 */
export function useAutosuggestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AutosuggestQuery, AutosuggestQueryVariables>) {
        return ApolloReactHooks.useQuery<AutosuggestQuery, AutosuggestQueryVariables>(AutosuggestDocument, baseOptions);
      }
export function useAutosuggestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AutosuggestQuery, AutosuggestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AutosuggestQuery, AutosuggestQueryVariables>(AutosuggestDocument, baseOptions);
        }
export type AutosuggestQueryHookResult = ReturnType<typeof useAutosuggestQuery>;
export type AutosuggestLazyQueryHookResult = ReturnType<typeof useAutosuggestLazyQuery>;
export type AutosuggestQueryResult = ApolloReactCommon.QueryResult<AutosuggestQuery, AutosuggestQueryVariables>;