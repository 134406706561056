import { MESSAGES, getBookingsListTableHeaders } from './headers'

import { RideCategory } from '../generated/graphql'

const DEFAULT_LOCALE = 'en'

const DATE_FORMAT = 'dd.MM.yyyy HH:mm'
const DATE_FORMAT_WITH_UTC = 'dd.MM.yyyy HH:mm (z)'
const INITIAL_FILTER_VALUE = RideCategory.Ondemand
const INITIAL_PAGE_VALUE = 1
const LOCALISED_DATE_FORMAT = "dd.MM.yyyy HH:mm (U'T'CZ)"
const REFRESH_TOKEN_QUERY_PARAM = 'refresh_token'

export {
  DATE_FORMAT_WITH_UTC,
  DATE_FORMAT,
  DEFAULT_LOCALE,
  INITIAL_FILTER_VALUE,
  INITIAL_PAGE_VALUE,
  LOCALISED_DATE_FORMAT,
  MESSAGES,
  REFRESH_TOKEN_QUERY_PARAM,
  getBookingsListTableHeaders,
}
